// src/pages/PrintInstructions.js
import React, { useState, useEffect, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const PrintInstructionsRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-whitesmoke);
  font-family: var(--small);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  margin-bottom: 20px;
`;

const SearchBar = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  height: 36px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
  .MuiInputBase-root {
    padding: 0;
    height: 36px;
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiAutocomplete-inputRoot {
    padding: 8px 10px;
  }

  .MuiAutocomplete-option {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
  }
`;

const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MenuSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const MenuItem = styled.div`
  padding: 10px;
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  border: 1px solid var(--color-black);
  padding: 10px;
  background-color: var(--color-gray-300);

  @media print {
    background-color: #e0e0e0 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;

const TableCell = styled.td`
  border: 1px solid var(--color-black);
  padding: 10px;
`;

const PrintButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const PrintStyles = createGlobalStyle`
  @page {
    size: A4 landscape;
    margin: 20mm;
  }

  @media print {
    body * {
      visibility: hidden;
    }

    #printableContent, #printableContent * {
      visibility: visible;
    }

    #printableContent {
      position: absolute;
      left: 0;
      top: 0;
      color: black;
    }
  }
`;

const DisabledSearchBar = styled(SearchBar)`
  opacity: 0.7;
  pointer-events: none;
  background-color: var(--color-gray-100);
`;

const TotalCell = styled(TableCell)`
  font-weight: bold;
  background-color: var(--color-gray-100);
  text-align: right;
  padding-right: 20px;

  @media print {
    background-color: #f0f0f0 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;

const TotalHeader = styled(TableHeader)`
  font-weight: bold;
  background-color: var(--color-gray-300);
  text-align: right;
  padding-right: 20px;
  width: 200px;

  @media print {
    background-color: #e0e0e0 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;

const PrintInstructions = () => {
  const location = useLocation();
  const { clientId, clientName } = location.state || {};
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  
  useEffect(() => {
    if (clientId) {
      // Se tiver clientId, já carrega os dados do cliente específico
      const db = getDatabase();
      const customerRef = ref(db, `customers/${clientId}`);
      onValue(customerRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setCustomers([{ id: clientId, ...data }]);
          setSelectedUser(clientId);
          setRecipes(data.recipes || []);
        }
      });
    } else {
      // Comportamento original - carrega todos os clientes
      const db = getDatabase();
      const customersRef = ref(db, "customers");
      onValue(customersRef, (snapshot) => {
        const data = snapshot.val();
        setCustomers(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      });
    }
  }, [clientId]);
  
  useEffect(() => {
    const db = getDatabase();
    const ingredientsRef = ref(db, "recipes");
    onValue(ingredientsRef, (snapshot) => {
      const data = snapshot.val();
      setIngredients(data);
    });
  }, []);
  
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const handleUserChange = (event, value) => {
    setSelectedUser(value ? value.id : "");
    const customer = customers.find((customer) => customer.id === (value ? value.id : ""));
    setRecipes(customer ? customer.recipes : []);
    setSelectedMenus([]); // Clear selected menus when a new client is selected
  };
  
  const handleMenuClick = (menu) => {
    setSelectedMenus((prev) =>
      prev.includes(menu) ? prev.filter((m) => m !== menu) : [...prev, menu]
    );
  };
  
  const handlePrint = () => {
    window.print();
  };
  
  const getIngredientsForSelectedMenus = () => {
    const ingredientsList = {};
    selectedMenus.forEach((menu) => {
      Object.keys(menu.recipes || {}).forEach((recipeId) => {
        const recipe = ingredients[recipeId];
        if (recipe && Array.isArray(recipe.ingredients)) {
          recipe.ingredients.forEach((ingredient) => {
            if (!ingredientsList[ingredient.name]) {
              ingredientsList[ingredient.name] = { quantity: 0, recipes: {} };
            }
            ingredientsList[ingredient.name].quantity += Number(ingredient.quantity_g_or_ml);
            if (!ingredientsList[ingredient.name].recipes[recipeId]) {
              ingredientsList[ingredient.name].recipes[recipeId] = 0;
            }
            ingredientsList[ingredient.name].recipes[recipeId] += Number(ingredient.quantity_g_or_ml);
          });
        }
      });
    });
    return ingredientsList;
  };
  
  const ingredientsList = getIngredientsForSelectedMenus();
  
  const currentDateTime = new Date().toLocaleString("pt-BR", {
    timeZone: "America/Sao_Paulo",
  });
  
  return (
    <PrintInstructionsRoot>
      <PrintStyles />
      <FunctionsHeader />
      <MainContent>
        <ContentWrapper>
          <SectionTitle>
            {clientId ? `Cliente: ${clientName}` : 'Selecione o Cliente'}
          </SectionTitle>
          
          {clientId ? (
            <DisabledSearchBar>
              <StyledAutocomplete
                value={customers.find(c => c.id === clientId)}
                options={[]}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    value={clientName}
                    disabled
                  />
                )}
              />
            </DisabledSearchBar>
          ) : (
            <SearchBar>
              <StyledAutocomplete
                freeSolo
                options={filteredCustomers}
                getOptionLabel={(option) => option.name}
                onInputChange={(event, newInputValue) => {
                  setSearchTerm(newInputValue);
                }}
                onChange={handleUserChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Pesquisar cliente..."
                  />
                )}
              />
              <SearchButton>
                <img src="/group-4.svg" alt="Search Icon" />
              </SearchButton>
            </SearchBar>
          )}

          <SectionTitle>
            {clientId ? 
              `Selecione os cardápios de ${clientName}` : 
              'Selecione os Cardápios'}
          </SectionTitle>
          <MenuSelect>
            {recipes.map((recipe, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuClick(recipe)}
                style={{
                  backgroundColor: selectedMenus.includes(recipe)
                    ? "var(--color-darkorange)"
                    : "var(--color-white)",
                  color: selectedMenus.includes(recipe)
                    ? "var(--color-white)"
                    : "var(--color-black)",
                }}
              >
                {recipe.diet_name}
              </MenuItem>
            ))}
          </MenuSelect>
  
          <div id="printableContent">
            <h1>Instruções para cozinha</h1>
            <h2>Cliente: {selectedUser ? customers.find(customer => customer.id === selectedUser)?.name : "-"}</h2>
            <h3>Data e hora da impressão: {currentDateTime}</h3>
            <Table id="printableTable">
              <thead>
                <tr>
                  <TableHeader></TableHeader>
                  <TableHeader>Ingredientes</TableHeader>
                  {selectedMenus.flatMap(menu => Object.keys(menu.recipes || {})).map((recipeId, index) => (
                    <TableHeader key={index}>{ingredients[recipeId]?.description || `Receita ${recipeId}`}</TableHeader>
                  ))}
                  <TotalHeader>Peso Total do Ingrediente em g ou ml</TotalHeader>
                </tr>
              </thead>
              <tbody>
                {Object.keys(ingredientsList).map((ingredientName, index) => (
                  <tr key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{ingredientName}</TableCell>
                    {selectedMenus.flatMap(menu => Object.keys(menu.recipes || {})).map((recipeId, recipeIndex) => (
                      <TableCell key={recipeIndex}>
                        {ingredientsList[ingredientName].recipes[recipeId] || "-"}
                      </TableCell>
                    ))}
                    <TotalCell>
                      {ingredientsList[ingredientName].quantity}
                    </TotalCell>
                  </tr>
                ))}
              </tbody>
            </Table>
            <SectionTitle>Instruções das Receitas</SectionTitle>
            {selectedMenus.map((menu, menuIndex) => (
              <div key={menuIndex}>
                <h3>{menu.diet_name}</h3>
                {Object.keys(menu.recipes || {}).map((recipeId, recipeIndex) => (
                  <div key={recipeId} style={{ marginLeft: "20px" }}>
                    <h4>{recipeIndex + 1}. {ingredients[recipeId]?.description || `Receita ${recipeId}`}</h4>
                    <p style={{ marginLeft: "20px" }}>{ingredients[recipeId]?.instructions || "Sem instruções"}</p>
                    <p style={{ marginLeft: "20px" }}>Ingredientes: {Array.isArray(ingredients[recipeId]?.ingredients) ? ingredients[recipeId].ingredients.map((ing) => ing.name).join(", ") : "Sem ingredientes"}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
  
          <PrintButton onClick={handlePrint}>Imprimir</PrintButton>
        </ContentWrapper>
      </MainContent>
    </PrintInstructionsRoot>
  );
};
  
export default PrintInstructions;