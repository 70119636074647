import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDatabase, ref, onValue } from "firebase/database";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  color: black;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: var(--color-darkorange);
`;

const TablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const NutritionalTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  text-align: left;
  border: 2px solid var(--color-gray-300);

  th {
    font-weight: bold;
    font-size: 1.1rem;
    border: 1px solid var(--color-gray-300);
  }

  .center {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border: 1px solid var(--color-gray-300);
  }

  .sub-header {
    font-size: 1rem;
    text-align: center;
    font-weight: normal;
    margin-bottom: 5px;
    border: 1px solid var(--color-gray-300);
  }

  td {
    padding: 5px;
    border: 1px solid var(--color-gray-300);
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: var(--color-darkorange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const formatNumberWithComma = (value) => {
  if (!value) return '0,0';
  return parseFloat(value).toFixed(1).replace('.', ',');
};

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const IngredientName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const IngredientCode = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
`;

const IngredientNutritionalModal = ({ ingredient, onClose }) => {
  const [nutritionalData, setNutritionalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseQuantity = 100;
  const appliedQuantity = ingredient.quantity_g_or_ml;

  useEffect(() => {
    const db = getDatabase();
    const ingredientRef = ref(db, `ingredients/${ingredient.id}`);

    onValue(ingredientRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.nutritionalValues) {
        setNutritionalData(data.nutritionalValues);
      }
      setLoading(false);
    });
  }, [ingredient.id]);

  if (loading) {
    return (
      <ModalOverlay>
        <ModalContent>
          <Title>Carregando informações nutricionais...</Title>
        </ModalContent>
      </ModalOverlay>
    );
  }

  if (!nutritionalData) {
    return (
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <Title>Dados nutricionais não disponíveis para {ingredient.name}</Title>
          <Button onClick={onClose}>Fechar</Button>
        </ModalContent>
      </ModalOverlay>
    );
  }

  const calculateAppliedValue = (baseValue) => {
    if (!baseValue) return 0;
    return (baseValue * appliedQuantity) / baseQuantity;
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <TablesContainer>
          <TableWrapper>
            <NutritionalTable>
              <thead>
                <tr>
                  <th colSpan="3" className="center">
                    <TableHeader>
                      <IngredientName>{ingredient.name}</IngredientName>
                      <IngredientCode>Código: {ingredient.id}</IngredientCode>
                    </TableHeader>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>100g</th>
                  <th>{formatNumberWithComma(appliedQuantity)}g</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Valor energético (kcal)</td>
                  <td>{formatNumberWithComma(nutritionalData.energyKcal_kcal)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.energyKcal_kcal))}</td>
                </tr>
                <tr>
                  <td>Carboidratos (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.carbohydrates_g?.total_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.carbohydrates_g?.total_g))}</td>
                </tr>
                <tr>
                  <td>Proteínas (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.proteins_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.proteins_g))}</td>
                </tr>
                <tr>
                  <td>Gorduras totais (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.fats_g?.total_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.fats_g?.total_g))}</td>
                </tr>
                <tr>
                  <td>Gorduras saturadas (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.fats_g?.saturated_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.fats_g?.saturated_g))}</td>
                </tr>
                <tr>
                  <td>Gorduras trans (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.fats_g?.trans_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.fats_g?.trans_g))}</td>
                </tr>
                <tr>
                  <td>Fibras alimentares (g)</td>
                  <td>{formatNumberWithComma(nutritionalData.dietaryFiber_g)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.dietaryFiber_g))}</td>
                </tr>
                <tr>
                  <td>Sódio (mg)</td>
                  <td>{formatNumberWithComma(nutritionalData.sodium_mg)}</td>
                  <td>{formatNumberWithComma(calculateAppliedValue(nutritionalData.sodium_mg))}</td>
                </tr>
              </tbody>
            </NutritionalTable>
          </TableWrapper>
        </TablesContainer>
        <Button onClick={onClose}>Fechar</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default IngredientNutritionalModal; 