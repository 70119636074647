import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";
import SelecionarReceitasModal from "../components/SelecionarReceitasModal";

const ImprimirRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  text-align: center; /* Center the text */
  font-size: var(--font-size-xs);
  color: var(--color-whitesmoke);
  font-family: var(--small);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content within the wrapper */
  max-width: 1200px; /* You can adjust this value to fit your design */
  width: 100%;
`;

const PrintMessage = styled.h1`
  font-size: 2rem; /* Adjust font size for better visibility */
  color: var(--color-white);
  margin-bottom: 60px; /* Set margin-bottom to 60px */
  font-weight: normal; /* Ensure the text is not bold */
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center; /* Center the items */
  width: 100%;
`;

const MenuItem = styled.div`
  width: 100%;
  max-width: 250px;
  text-align: center;
  cursor: pointer;
`;

const MenuItemImage = styled.img`
  width: 100%;
  border-radius: var(--br-5xl);
  object-fit: cover;
`;

const MenuItemText = styled.div`
  margin-top: 10px;
  font-size: var(--h4-size);
`;

const Imprimir = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, clientName } = location.state || {};
  const [userName, setUserName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal
  const [selectedRecipe, setSelectedRecipe] = useState(null); // State to store selected recipe

  const onClientPrintClick = useCallback(() => {
    navigate("/imprimir/clientes");
  }, [navigate]);

  const onReceipesPrintClick = useCallback(() => {
    navigate("/imprimir/instrucoes-cozinha", { 
      state: { clientId, clientName } 
    });
  }, [navigate, clientId, clientName]);

  const onLogoClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const handleUserDataFetched = useCallback((userData) => {
    setUserName(userData.name);
  }, []);

  const handleNutritionalLabelClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleSaveRecipes = useCallback((selectedRecipes) => {
    if (selectedRecipes.length > 0) {
      const recipeId = selectedRecipes[0].id;
      navigate(`/receita/${recipeId}/ingredientes`, clientId ? {
        state: { clientId, clientName }
      } : undefined);
    }
    setIsModalOpen(false);
  }, [navigate, clientId, clientName]);

  return (
    <ImprimirRoot>
      <FunctionsHeader
        onLogoClick={onLogoClick}
        onUserDataFetched={handleUserDataFetched}
      />
      <Breadcrumb path={clientName ? 
        `Página inicial > Clientes > ${clientName} > Impressão` : 
        "Página inicial > Impressão"} 
      />
      <MainContent>
        <ContentWrapper>
          <PrintMessage>
            {clientName ? 
              `O que deseja imprimir para ${clientName}?` : 
              "O que deseja imprimir?"}
          </PrintMessage>
          <MenuContainer>
            <MenuItem onClick={onReceipesPrintClick}>
              <MenuItemImage src="/rectangle-155@2x.png" alt="Receitas" />
              <MenuItemText>Instruções para Cozinha</MenuItemText>
            </MenuItem>
            <MenuItem onClick={handleNutritionalLabelClick}>
              <MenuItemImage src="/rectangle-155@2x.png" alt="Receitas" />
              <MenuItemText>Rótulo Nutricional</MenuItemText>
            </MenuItem>
          </MenuContainer>
        </ContentWrapper>
      </MainContent>
      {isModalOpen && (
        <SelecionarReceitasModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveRecipes}
          clientId={clientId}
          clientName={clientName}
        />
      )}
    </ImprimirRoot>
  );
};

export default Imprimir;