import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref as databaseRef, onValue } from "firebase/database";
import { getStorage, getDownloadURL, ref as storageRef } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "./GlobalSearch";

const HeaderContainer = styled.header`
  width: 100%;
  background-color: #000;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-bottom: 2px solid var(--color-orange);
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
`;

const TopSection = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
`;

const Logo = styled.img`
  height: 75px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const CenterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  height: 36px; /* Ensure the height is fixed */
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px 10px; /* Adjust padding to fit within the height */
  border: none;
  outline: none;
  font-size: 1rem;
`;

const SearchButton = styled.button`
  width: 36px; /* Same height and width for a square button */
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const UserInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const UserImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 5px; /* Add margin to separate the image from the name */
`;

const UserName = styled.div`
  color: var(--color-white);
  font-size: 1rem;
  text-align: center; /* Center the text */
  white-space: nowrap; /* Prevent line break */
`;

const UserRole = styled.div`
  color: var(--color-white);
  font-size: 0.8rem;
  text-align: center; /* Center the text */
`;

const DropdownMenu = styled.div`
  position: fixed; /* Change to fixed to overlay the screen */
  top: 70px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 20px; /* Increase padding for more spacing */
  border-radius: var(--br-5xs);
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: 1000;
`;

const MenuSection = styled.nav`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  font-size: 1rem;
  color: var(--color-white);

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  padding: 10px 0; /* Increase padding for easier clicking */

  &:hover {
    color: var(--color-orange);
    text-decoration: underline;
  }
`;

const FunctionsHeader = ({ onUserDataFetched }) => {
  const [userData, setUserData] = useState({ name: "", picture: "/default-avatar.png", role: "" });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase();
      const userRef = databaseRef(db, `users/${user.uid}`);

      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const { name, photo, role } = data;
          const storage = getStorage();
          const pictureRef = photo ? storageRef(storage, photo) : null;

          if (pictureRef) {
            getDownloadURL(pictureRef)
              .then((url) => {
                setUserData({
                  name,
                  picture: url,
                  role: role || "",
                  uid: user.uid
                });
                if (onUserDataFetched) {
                  onUserDataFetched({ name, picture: url, role });
                }
              })
              .catch((error) => {
                console.error("Error fetching profile picture: ", error);
                setUserData({
                  name,
                  picture: "/default-avatar.png",
                  role: role || "",
                  uid: user.uid
                });
                if (onUserDataFetched) {
                  onUserDataFetched({ name, picture: "/default-avatar.png", role });
                }
              });
          } else {
            setUserData({
              name,
              picture: "/default-avatar.png",
              role: role || "",
              uid: user.uid
            });
            if (onUserDataFetched) {
              onUserDataFetched({ name, picture: "/default-avatar.png", role });
            }
          }
        }
      });
    }
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <HeaderContainer>
      <TopSection>
        <Logo src="/soulben-bordado@2x.png" alt="SoulBen Logo" onClick={() => navigate("/home")} />
        <CenterSection>
          <SearchContainer>
            <GlobalSearch placeholder="Pesquise por: Ingredientes, Receitas ou Clientes" />
            <SearchButton>
              <img src="/group-4.svg" alt="Search Icon" />
            </SearchButton>
          </SearchContainer>
          <MenuSection>
            <MenuItem onClick={() => navigate("/selecione-um-cliente")}>Clientes</MenuItem>
            <MenuItem onClick={() => navigate("/busque-ou-pesquise-por-um-ingrediente")}>Ingredientes</MenuItem>
            <MenuItem onClick={() => navigate("/criar-ingrediente")}>Criar um ingrediente</MenuItem>
            <MenuItem onClick={() => navigate("/criar-receita")}>Criar uma receita</MenuItem>
          </MenuSection>
        </CenterSection>
        <UserInfoContainer onClick={() => setDropdownOpen(!dropdownOpen)}>
          <UserImage src={userData.picture} alt="User Image" />
          <UserName>{userData.name}</UserName>
          <UserRole>
            {userData.role === "admin" ? "Administrador" : userData.role === "nutri" ? "Nutricionista" : userData.role}
          </UserRole>
          <DropdownMenu show={dropdownOpen}>
            <MenuItem onClick={() => navigate(`/editar-perfil/${userData.uid}`)}>Editar Perfil</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </DropdownMenu>
        </UserInfoContainer>
      </TopSection>
    </HeaderContainer>
  );
};

export default FunctionsHeader;
